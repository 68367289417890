@import "variables";
@import "mixins";

.list {
    list-style: none;
    margin-block-end: 0;
    @include scale(padding-inline-start, $list-padding-inline-start-base,
            $list-padding-inline-start-md, $list-padding-inline-start-lg);

    text-align: left;

    .list-item {

        &:last-child {
            .list-item-body {
                margin-bottom: 0 !important;
            }
        }

        &:before {
            content: '';
            display: inline-block;
            height: $list-bullet-size;
            width: $list-bullet-size;
            @include scale(margin-right, $list-item-bullet-margin-right-base,
                    $list-item-bullet-margin-right-md, $list-item-bullet-margin-right-lg);
            background-image: url('../assets/images/Bullet.svg');
        }

        .list-item-title {
            display: inline;

            color: $primary;
            text-transform: uppercase;
            font-weight: 600;
            @include scale(font-size, $list-item-title-font-size-base,
                    $list-item-title-font-size-md, $list-item-title-font-size-lg);
        }

        .list-item-body {
            margin-left: ($list-bullet-size / 2) - ($list-item-body-border-width / 2);
            @include scale(margin-top, $list-item-body-margin-y-base, $list-item-body-margin-y-md, $list-item-body-margin-y-lg);
            @include scale(margin-bottom, $list-item-body-margin-y-base, $list-item-body-margin-y-md, $list-item-body-margin-y-lg);
            @include scale(padding-left, $list-item-body-padding-left-base,
                    $list-item-body-padding-left-md, $list-item-body-padding-left-lg);
            border-left: $primary solid;
            border-left-width: 1px;

            .list-item-caption {
                color: $text;
                padding-bottom: 20px;
                font-size: inherit;
            }

            .list-item-button {
                font-size: small;
                display: inline-block;
                margin-bottom: 30px;
                text-transform: uppercase;
                color: $dark;
                background-color: $grey;
                border: 0;

                padding: 7px 15px;
            }
        }
    }
}

.list-final-border {
    max-width: 105px;
    border-top: $primary solid;
    border-top-width: $list-item-body-border-width;

    @include scale(margin-left, $list-final-border-margin-left-base,
            $list-final-border-margin-left-md, $list-final-border-margin-left-lg)
}
