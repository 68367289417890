@import "variables";
@import "mixins";

@keyframes ticker-kf {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-($slider-img-width + 2 * $slider-img-spacer) * 6, 0);
    }
}


.catalogue {

    .catalogue-item {
        //animation: ticker-kf 20s linear infinite;
        //flex: 0 0 20%;
        //max-width: 20%;
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            //padding: 20px;

            img{
                filter: grayscale(1);
                width: 100%;
                height: auto;
                max-width: 120px;
            }
        }
    }
}
