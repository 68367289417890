@import "variables";
@import "mixins";

#footer {
    @include scale(padding-top, $footer-vertical-padding-base, $footer-vertical-padding-md, $footer-vertical-padding-lg);
    @include scale(padding-bottom, $footer-vertical-padding-base, $footer-vertical-padding-md, $footer-vertical-padding-lg);

    background-color: $dark;
    color: $light;

    a {
        color: $light;

    }

    .footer-social-link {
        font-size: 15px;
        padding: 7px;
        text-align: center;
        text-decoration: none;
        margin: 5px 2px;

        border-style: solid;
        border-color: $light;
        border-width: 3px;
        border-radius: 50%;
    }

    .fa-facebook-f {
        padding-right: 10px;
        padding-left: 10px;
    }

    .fa-instagram {
        padding-right: 9px;
        padding-left: 9px;
    }

    .fa-linkedin-in {
        padding-right: 8px;
        padding-left: 8px;
    }
}

.footer-legal-link {
    display: block;
    line-height: 1;
    font-size: 0.8rem;
}
