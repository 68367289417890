// Colors
$primary: #7FBA27;
$text: #747474;
$dark: #333333;
$light: #ffffff;
$grey: #D9D9D9;

$rgba-light: rgba(255, 255, 255, 0.8);

$navbar-height-base: 65px;
$navbar-height-md: 75px;
$navbar-height-lg: 90px;

$navbar-logo-width-base: 115px;
$navbar-logo-width-md: 167px;
$navbar-logo-width-lg: 178px;

$section-padding: 75px;

$slider-img-width:10vw;
$slider-img-spacer:10vw;

// Hamburger setup
$hamburger-layer-color: $dark;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 26px;
$hamburger-layer-height: 3px;
$hamburger-layer-border-radius: 2px;
$hamburger-layer-spacing: 8px;

// Global font sizes, line heights and letters spacing
$font-size-base: 1rem;
$font-size-md: 1rem;
$font-size-lg: 1.25rem;

$section-title-font-size-base: 1.875rem;
$section-title-font-size-md: 2.8125rem;
$section-title-font-size-lg: 3.4375rem;

$main-title-font-size-base: 2.5rem;
$main-title-font-size-md: 3.4375rem;
$main-title-font-size-lg: 4.0625rem;

$main-subtitle-font-size-base: 0.875rem;
$main-subtitle-font-size-md: 0.875rem;
$main-subtitle-font-size-lg: 1.375rem;

$main-capture-font-size-base: 1.125rem;
$main-capture-font-size-md: $main-capture-font-size-base;
$main-capture-font-size-lg: 1.5625rem;

$title-font-size-base: 2.1875rem;
$title-font-size-md: 3.4375rem;
$title-font-size-lg: 4.0625rem;

$contact-button-font-size-base: 1rem;
$contact-button-font-size-md: 1.25rem;
$contact-button-font-size-lg: $contact-button-font-size-md;

$person-card-name-font-size-base: 1.25rem;
$person-card-name-font-size-md: $person-card-name-font-size-base;
$person-card-name-font-size-lg: 1.5625rem;

$person-card-role-font-size-base: $person-card-name-font-size-base;
$person-card-role-font-size-md: $person-card-name-font-size-base;
$person-card-role-font-size-lg: 1rem;

// Global spaces
$base-container-padding: 15px;

$font-line-height-base: 1.875rem;
$font-line-height-md: $font-line-height-base;
$font-line-height-lg: 2rem;

$left-space-base: 20px;
$left-space-md: 79px;
$left-space-lg: 130px;

$early-container-padding-x-base: 40px + $base-container-padding;
$early-container-padding-x-md: 86px + $base-container-padding;
$early-container-padding-x-lg: 90px + $base-container-padding;

$late-container-padding-x-base: $base-container-padding;
$late-container-padding-x-md: 86px + $base-container-padding;
$late-container-padding-x-lg: 90px + $base-container-padding;

$section-title-line-height-base: 3rem;
$section-title-line-height-md: 4.5rem;
$section-title-line-height-lg: 5.5rem;

$section-title-letter-spacing-base: 2px;
$section-title-letter-spacing-md: 3px;
$section-title-letter-spacing-lg: 4px;

$section-header-padding-bottom-base: 30px;
$section-header-padding-bottom-md: 30px;
$section-header-padding-bottom-lg: 50px;

$section-vertical-padding-base: 60px;
$section-vertical-padding-md: 90px;
$section-vertical-padding-lg: 90px;

$main-title-line-height-base: 3rem;
$main-title-line-height-md: $main-title-line-height-base;
$main-title-line-height-lg: 4rem;

$main-title-letter-spacing-base: 2.4px;
$main-title-letter-spacing-md: $main-title-letter-spacing-base;
$main-title-letter-spacing-lg: 3.95px;

$main-subtitle-line-height-base: 1.5rem;
$main-subtitle-line-height-md: $main-subtitle-line-height-base;
$main-subtitle-line-height-lg: 2.375rem;

$main-subtitle-letter-spacing-base: 7.48px;
$main-subtitle-letter-spacing-md: $main-subtitle-letter-spacing-base;
$main-subtitle-letter-spacing-lg: 12.32px;

$main-capture-letter-spacing-base: 0.36px;
$main-capture-letter-spacing-md: $main-capture-letter-spacing-base;
$main-capture-letter-spacing-lg: 0.5px;

$contact-button-line-height-base: 1.75rem;
$contact-button-line-height-md: $contact-button-line-height-base;
$contact-button-line-height-lg: 2.75rem;

$contact-button-letter-spacing-base: 0;
$contact-button-letter-spacing-md: 0;
$contact-button-letter-spacing-lg: 0;

$contact-button-padding-base: 10px 34px;
$contact-button-padding-md: 15px 52px;
$contact-button-padding-lg: $contact-button-padding-md;

$list-bullet-size: 18.38px;
$list-item-body-border-width: 1px;

$list-padding-inline-start-base: 0px;
$list-padding-inline-start-md: 36px;
$list-padding-inline-start-lg: 28px;

$list-item-body-padding-left-base: 21px;
$list-item-body-padding-left-md: 31px;
$list-item-body-padding-left-lg: $list-item-body-padding-left-md;

$list-item-body-margin-y-base: 30px;
$list-item-body-margin-y-md: 22px;
$list-item-body-margin-y-lg: 30px;

$list-item-title-font-size-base: 1.125rem;
$list-item-title-font-size-md: 1.125rem;
$list-item-title-font-size-lg: 1.5625rem;

$list-item-bullet-margin-right-base: $list-item-body-padding-left-base - ($list-bullet-size / 2);
$list-item-bullet-margin-right-md: $list-item-body-padding-left-md - ($list-bullet-size / 2);
$list-item-bullet-margin-right-lg: $list-item-body-padding-left-lg - ($list-bullet-size / 2);

$list-final-border-margin-left-base: $list-padding-inline-start-base + ($list-bullet-size / 2) - ($list-item-body-border-width / 2);
$list-final-border-margin-left-md: $list-padding-inline-start-md + ($list-bullet-size / 2) - ($list-item-body-border-width / 2);
$list-final-border-margin-left-lg: $list-padding-inline-start-lg + ($list-bullet-size / 2) - ($list-item-body-border-width / 2);

$chi-siamo-caption-margin-bottom-base: 63px;
$chi-siamo-caption-margin-bottom-md: 34px;
$chi-siamo-caption-margin-bottom-lg: 60px;

$person-card-photo-size-base: 200px;
$person-card-photo-size-md: $person-card-photo-size-base;
$person-card-photo-size-lg: 200px;

$person-card-name-letter-spacing-base: 1.75px;
$person-card-name-letter-spacing-md: $person-card-name-letter-spacing-base;
$person-card-name-letter-spacing-lg: 3.75px;

$servizi-caption-margin-bottom-base: 50px;
$servizi-caption-margin-bottom-md: 70px;
$servizi-caption-margin-bottom-lg: 40px;

$clienti-title-margin-bottom-base: 40px;
$clienti-title-margin-bottom-md: 80px;
$clienti-title-margin-bottom-lg: 77px;

$footer-vertical-padding-base: 22px;
$footer-vertical-padding-md: $footer-vertical-padding-base;
$footer-vertical-padding-lg: 35px;

$footer-menu-vertical-padding-base: 30px;
$footer-menu-vertical-padding-md: 45px;
$footer-menu-vertical-padding-lg: 55px;

$footer-menu-logo-width: 151px;
