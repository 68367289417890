@import "variables";

#vantaggi {
    background-color: $dark;
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    color: $light;

    .catalogue-item-body-title {
        line-height: 1;
    }
}

#case_histories {
    padding-top: $section-padding;
    padding-bottom: $section-padding;
}

#index-servizi {
    //p{
    //    line-height: 1.5rem;
    //}
    .servizi-title {
        padding: 1rem 0;
        color: $primary;
        text-align: center;
    }

    .servizi-image {
        display: flex;
        align-items: center;
        padding: 1rem 0;

        img {

            height: auto;
            width: 100%;
        }
    }
}
