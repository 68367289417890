@import "variables";
@import "mixins";

.hero-image-contatti {
    background-image: url("../assets/images/HeroContattaci.png");
}

#contattaci {

    .contattaci-form {

        input {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            -webkit-box-shadow: 0 7px 27px #00000040;
            -moz-box-shadow: 0 7px 27px #00000040;
            box-shadow: 0 7px 27px #00000040;
            -webkit-appearance: none;
            height: 48px;
            border: 0;
            padding: 10px;
            font-weight: lighter;
            @include scale(font-size, $contact-button-font-size-base, $contact-button-font-size-md, $contact-button-font-size-lg);
        }

        textarea {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            -webkit-box-shadow: 0 7px 27px #00000040;
            -moz-box-shadow: 0 7px 27px #00000040;
            box-shadow: 0 7px 27px #00000040;
            -webkit-appearance: none;
            height: 200px;
            border: 0;
            padding: 10px;
            font-weight: lighter;
            @include scale(font-size, $contact-button-font-size-base, $contact-button-font-size-md, $contact-button-font-size-lg);
        }

        .submit-btn {
            background-color: $dark;
            border: 0;
            padding: 1rem;
            width: fit-content;

            @include scale(padding, $contact-button-padding-base, $contact-button-padding-md, $contact-button-padding-lg);

            text-transform: uppercase;
            font-weight: normal;
            @include scale(font-size, $contact-button-font-size-base, $contact-button-font-size-md, $contact-button-font-size-lg);
            @include scale(line-height, $contact-button-line-height-base, $contact-button-line-height-md, $contact-button-line-height-lg);
            @include scale(letter-spacing, $contact-button-letter-spacing-base, $contact-button-letter-spacing-md, $contact-button-letter-spacing-lg);

            color: $light;
        }
    }
}

#indirizzo {
    padding-top: 110px;
    padding-bottom: 110px;
    background-color: $dark;
    color: $light;
}

#contatti{

    .contact-link{
        display: block;
        color: black;
    }

    .map-container{

    }
}
