@import "variables";
@import "~bootstrap-scss/bootstrap-grid";

@mixin scale($property, $base, $md-size, $lg-size) {
    #{$property}: $base !important;

    @include media-breakpoint-up(md) {
        #{$property}: $md-size !important;
    }

    @include media-breakpoint-up(lg) {
        #{$property}: $lg-size !important;
    }
}