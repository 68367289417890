@import "variables";
@import "mixins";
@import "~rfs/scss";

#navbar {
    background-color: $rgba-light;
    position: fixed;
    width: 100%;
    top: 0;
    @include scale('height', $navbar-height-base, $navbar-height-md, $navbar-height-lg);
    z-index: 9999;

    a {
        color: $light;
        text-decoration: none;
    }

    .navbar-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        #navbar-logo {
            @include scale(width, $navbar-logo-width-base, $navbar-logo-width-md, $navbar-logo-width-lg);
            height: auto;
        }

        .hamburger {
            z-index: 2;
            display: flex;

            &:focus {
                outline: 0;
            }

            &.is-active {
                .hamburger-box {
                    .hamburger-inner,
                    .hamburger-inner::before,
                    .hamburger-inner::after {
                        background-color: $light;
                    }
                }
            }

        }

        #navbar-overlay {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;

            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            height: 100vh;
            width: 100vw;

            padding: 15px;

            z-index: 1;
            overflow: auto;
            background: $dark;

            #navbar-overlay-logo {
                width: 200px;
                height: auto;
            }

            #navbar-overlay-menu {
                list-style: none;
                text-align: center;
                margin: 0;
                padding: 0;

                li {
                    text-transform: uppercase;
                    padding-bottom: 50px;
                    @include font-size(2.5625rem);

                    &:last-child {
                        padding-bottom: 0;
                    }
                }
            }

            #navbar-overlay-socials {
                @include font-size(2.5625rem);
            }
        }
    }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    #navbar {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}
