@import "variables";
@import "mixins";

.footer-menu {

    @include scale(padding-top, $footer-menu-vertical-padding-base, $footer-menu-vertical-padding-md,
            $footer-menu-vertical-padding-lg);
    @include scale(padding-bottom, $footer-menu-vertical-padding-base, $footer-menu-vertical-padding-md,
            $footer-menu-vertical-padding-lg);

    .footer-menu-logo {
        width: $footer-menu-logo-width;
        height: auto;
    }

    font-weight: 300;

    .footer-link{
        display: block;
    }
}
