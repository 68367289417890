@import "variables";
@import "mixins";

// Helvetica Neue font
@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeueBold.eot');
    src: url('../assets/fonts/HelveticaNeueBold.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueBold.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueBold.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeueMedium.eot');
    src: url('../assets/fonts/HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueMedium.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueMedium.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeueLight.eot');
    src: url('../assets/fonts/HelveticaNeueLight.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueLight.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueLight.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeueRegular.eot');
    src: url('../assets/fonts/HelveticaNeueRegular.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueRegular.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueRegular.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueRegular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../assets/fonts/HelveticaNeueThin.eot');
    src: url('../assets/fonts/HelveticaNeueThin.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/HelveticaNeueThin.woff2') format('woff2'),
    url('../assets/fonts/HelveticaNeueThin.woff') format('woff'),
    url('../assets/fonts/HelveticaNeueThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



// Logo Pagina46 font
@font-face {
    font-family: 'pagina46-logo-font';
    src:  url('../assets/fonts/FontLogoPagina46.eot?qr1d77');
    src:  url('../assets/fonts/FontLogoPagina46.eot?qr1d77#iefix') format('embedded-opentype'),
    url('../assets/fonts/FontLogoPagina46.ttf?qr1d77') format('truetype'),
    url('../assets/fonts/FontLogoPagina46.woff?qr1d77') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="pagina46-logo"], [class*=" pagina46-logo"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'pagina46-logo-font', sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pagina46-logo:before {
    content: "\e900";
    color: $primary;
    position: relative;
}

.text-light {
    color: $light;
}
