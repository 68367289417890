@import "variables";
@import "mixins";
@import "typography";
@import "navbar";
@import "list";
@import "catalogue";
@import "contattaci";
@import "servizi";
@import "footer_menu";
@import "footer";
@import "~bootstrap-scss/bootstrap";
@import "~hamburgers/_sass/hamburgers/hamburgers";

$fa-font-path: "../../node_modules/@fortawesome/fontawesome-free/webfonts";
@import "./node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "./node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "./node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "./node_modules/@fortawesome/fontawesome-free/scss/regular";

body {
    box-sizing: border-box;
    scroll-behavior: smooth;

    margin: 0;
    padding: 0;

    font-family: 'HelveticaNeue', sans-serif;
    font-weight: lighter;
    @include scale(font-size, $font-size-base, $font-size-md, $font-size-lg);
    @include scale(line-height, $font-line-height-base, $font-line-height-md, $font-line-height-lg);
}

html {
    scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.space-left {
    @include scale(margin-left, $left-space-base, $left-space-md, $left-space-lg);
}

.space-right {
    @include scale(margin-right, $left-space-base, $left-space-md, $left-space-lg);
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.space-x {
    @include scale(margin-left, $left-space-base, $left-space-md, $left-space-lg);
    @include scale(margin-right, $left-space-base, $left-space-md, $left-space-lg);
}

.early-container {
    @extend .container;

    @include scale(padding-left, $early-container-padding-x-base, $early-container-padding-x-md,
            $early-container-padding-x-lg);
    @include scale(padding-right, $early-container-padding-x-base, $early-container-padding-x-md,
            $early-container-padding-x-lg);
}

.early-half-container {
    @extend .container;

    @include scale(padding-left, $early-container-padding-x-base, $early-container-padding-x-md,
            $early-container-padding-x-lg);
    @include scale(padding-right, $early-container-padding-x-base, $early-container-padding-x-md,
            $early-container-padding-x-lg);
}

.late-container {
    @extend .container;

    @include scale(padding-left, $late-container-padding-x-base, $late-container-padding-x-md,
            $late-container-padding-x-lg);
    @include scale(padding-right, $late-container-padding-x-base, $late-container-padding-x-md,
            $late-container-padding-x-lg);
}

.early-container-overflow {
    width: calc(100% + #{$early-container-padding-x-base * 2 - $base-container-padding * 2});

    @include media-breakpoint-up(md) {
        width: 100%;
    }

    @include scale(margin-left, -$early-container-padding-x-base + $base-container-padding, 0, 0)
}

.texture_bg {
    background-image: url("../assets/images/Logo_bg_2.svg");
    background-repeat: repeat-y;
    background-size: 200%;
    background-position-x: 25%;
    @include scale(background-position-y, $navbar-height-base, $navbar-height-md, $navbar-height-lg);
}

.texture_bg_light {
    background-image: url("../assets/images/Logo_bg_2_light.svg");
    background-repeat: repeat-y;
    background-size: 100%;
}

.section-dark {
    background-color: $dark;
    color: $light;
}

.section-light {
    //background-color: $light;
    color: $dark;
}

.section-space-top {
    @include scale(padding-top, $section-vertical-padding-base, $section-vertical-padding-md, $section-vertical-padding-lg);
}

.section-space-bottom {
    @include scale(padding-bottom, $section-vertical-padding-base, $section-vertical-padding-md, $section-vertical-padding-lg);
}

.section-head {
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: normal;

    @include scale(padding-bottom, $section-header-padding-bottom-base, $section-header-padding-bottom-md,
            $section-header-padding-bottom-lg)
}


.section-title {
    margin-left: 20px;
    @include scale(font-size, $section-title-font-size-base, $section-title-font-size-md, $section-title-font-size-lg);
    @include scale(line-height, $section-title-line-height-base, $section-title-line-height-md,
            $section-title-line-height-lg);
    @include scale(letter-spacing, $section-title-letter-spacing-base, $section-title-letter-spacing-md,
            $section-title-letter-spacing-lg);
    @include scale(margin-left, 10px, 15px, 20px);
}

.main-title {
    text-transform: capitalize;
    font-weight: bold;
    @include scale(font-size, $main-title-font-size-base, $main-title-font-size-md, $main-title-font-size-lg);
    @include scale(line-height, $main-title-line-height-base, $main-title-line-height-md, $main-title-line-height-lg);
    @include scale(letter-spacing, $main-title-letter-spacing-base, $main-title-letter-spacing-md,
            $main-title-letter-spacing-lg);
}

.main-subtitle {
    text-transform: uppercase;
    font-weight: normal;
    @include scale(font-size, $main-subtitle-font-size-base, $main-subtitle-font-size-md, $main-subtitle-font-size-lg);
    @include scale(line-height, $main-subtitle-line-height-base, $main-subtitle-line-height-md, $main-subtitle-line-height-lg);
    @include scale(letter-spacing, $main-subtitle-letter-spacing-base, $main-subtitle-letter-spacing-md,
            $main-subtitle-letter-spacing-lg);
}

.main-capture {
    @include scale(font-size, $main-capture-font-size-base, $main-capture-font-size-md, $main-capture-font-size-lg);
    @include scale(letter-spacing, $main-capture-letter-spacing-base, $main-capture-letter-spacing-md,
            $main-capture-letter-spacing-lg);
}

.title {
    text-transform: capitalize;
    font-weight: bold;

    @include scale(font-size, $title-font-size-base, $title-font-size-md, $title-font-size-lg);
}

.contact-button {
    width: fit-content;

    @include scale(padding, $contact-button-padding-base, $contact-button-padding-md, $contact-button-padding-lg);

    text-transform: uppercase;
    font-weight: normal;
    @include scale(font-size, $contact-button-font-size-base, $contact-button-font-size-md, $contact-button-font-size-lg);
    @include scale(line-height, $contact-button-line-height-base, $contact-button-line-height-md, $contact-button-line-height-lg);
    @include scale(letter-spacing, $contact-button-letter-spacing-base, $contact-button-letter-spacing-md, $contact-button-letter-spacing-lg);

    color: $light;
    background-color: $primary;
    border: 0;
    //border-radius: 1rem;
}

.hero {
    width: 100%;
    height: 100vh;

    @include scale(padding-top, $navbar-height-base, $navbar-height-md, $navbar-height-lg);

    .hero-img {
        z-index: -1;
    }
}

#gestionale-hero {
    .hero-image-space {
        padding-top: 0;

        @include media-breakpoint-up(lg) {
            padding-top: 150px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 120px;
        }
    }
}

#sito-web-ecommerce-hero {
    .hero-image-space {
        padding-top: 0;

        @include media-breakpoint-up(lg) {
            padding-top: 150px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 120px;
        }
    }
}

#mission {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    background-color: $dark;
    color: $light;
}

.partner {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    background-color: $dark;
    color: $light;
}

.clienti {
    padding-top: $section-padding;
    padding-bottom: $section-padding;


    #clienti-title {
        @include scale(margin-bottom, $clienti-title-margin-bottom-base, $clienti-title-margin-bottom-md,
                $clienti-title-margin-bottom-lg)
    }
}

#tecnologie {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    #tecnologie-caption {
        @include scale(margin-bottom, $servizi-caption-margin-bottom-base, $servizi-caption-margin-bottom-md,
                $servizi-caption-margin-bottom-lg);
    }
}

#servizi {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    #servizi-caption {
        @include scale(margin-bottom, $servizi-caption-margin-bottom-base, $servizi-caption-margin-bottom-md,
                $servizi-caption-margin-bottom-lg);
    }
}

#cosa-facciamo {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    #cosa-facciamo-caption {
        @include scale(margin-bottom, $servizi-caption-margin-bottom-base, $servizi-caption-margin-bottom-md,
                $servizi-caption-margin-bottom-lg);
    }
}

#chi_siamo {
    padding-top: $section-padding;
    padding-bottom: $section-padding;

    #chi-siamo-caption {
        @include scale(margin-bottom, $chi-siamo-caption-margin-bottom-base, $chi-siamo-caption-margin-bottom-md,
                $chi-siamo-caption-margin-bottom-lg);
    }


    .person-card-photo {
        display: flex;
        justify-content: center;
        @include scale(width, $person-card-photo-size-base, $person-card-photo-size-md, $person-card-photo-size-lg);
        @include scale(height, $person-card-photo-size-base, $person-card-photo-size-md, $person-card-photo-size-lg);

    }

    .person-card-name {
        display: block;
        line-height: 1;
        text-transform: uppercase;
        @include scale(font-size, $person-card-name-font-size-base, $person-card-name-font-size-md,
                $person-card-name-font-size-lg);
        @include scale(letter-spacing, $person-card-name-letter-spacing-base, $person-card-name-letter-spacing-md,
                $person-card-name-letter-spacing-lg);

        color: $primary;

        margin-bottom: 10px;
    }

    .person-card-role {
        display: block;
        line-height: 1;
        @include scale(font-size, $person-card-role-font-size-base, $person-card-role-font-size-md,
                $person-card-role-font-size-lg);

        margin-bottom: 10px;
    }

    .person-card-caption {
        color: $text;
    }

    .person-card-socials-box {
        display: flex;
        justify-content: center;

        .social-link {
            width: 2rem;
            height: 2rem;
            margin: 0 5px;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $light;
            font-size: 1rem;
            background-color: $primary;
            border-radius: 1rem;
            transition: opacity 200ms;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

.person-card-photo {
    display: flex;
    justify-content: center;
    @include scale(width, $person-card-photo-size-base, $person-card-photo-size-md, $person-card-photo-size-lg);
    @include scale(height, $person-card-photo-size-base, $person-card-photo-size-md, $person-card-photo-size-lg);

}

.person-card-name {
    display: block;
    line-height: 1;
    text-transform: uppercase;
    @include scale(font-size, $person-card-name-font-size-base, $person-card-name-font-size-md,
            $person-card-name-font-size-lg);
    @include scale(letter-spacing, $person-card-name-letter-spacing-base, $person-card-name-letter-spacing-md,
            $person-card-name-letter-spacing-lg);

    color: $primary;

    margin-bottom: 10px;
}

.person-card-role {
    display: block;
    line-height: 1;
    @include scale(font-size, $person-card-role-font-size-base, $person-card-role-font-size-md,
            $person-card-role-font-size-lg);

    margin-bottom: 10px;
}

.person-card-caption {
    color: $text;
}

.person-card-socials-box {
    display: flex;
    justify-content: center;

    .social-link {
        width: 2rem;
        height: 2rem;
        margin: 0 5px;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $light;
        font-size: 1rem;
        background-color: $primary;
        border-radius: 1rem;
        transition: opacity 200ms;

        &:hover {
            opacity: 0.7;
        }
    }
}
